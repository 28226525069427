//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";
import { TelemetryService } from "../../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-workflow")
@Component({
    selector: "s25-ng-workflow",
    template: `
        <div *ngIf="this.init" id="workflowSec" class="c-margin-left--half c-margin-top--half  c-margin-bottom--half ">
            <h2 class="c-margin-bottom--single">Workflow Settings</h2>

            Tier include:
            <ul>
                <li>Notification Policy on Event Type/Organizations (approval required only)</li>
                <li>Location Assignment then Location Notification</li>
                <li>Resource Assignment then Resource Notification</li>
                <li>Notification Policy generated by previous tasks (eg. Requirement Notification)</li>
            </ul>

            <div id="workflow">
                <p class="ngBold c-margin-bottom-half">Use Tiered Workflow <br /><br /></p>
                <s25-toggle-button
                    (modelValueChange)="this.onToggleChange($event)"
                    [modelValue]="this.workflowChained"
                ></s25-toggle-button>
            </div>

            <!--
            <div>
                <label class="c-displayBlock" for="confirmed">
                    <input
                        type="checkbox"
                        id="confirmed"
                        [checked]="workflowConfirmComplete"
                        (change)="onChange($event, 'complete')"
                    />
                    Change Event State to "Confirmed" when all tasks are complete
                </label>
            </div>

            <div>
                <label class="c-displayBlock" for="cancelled">
                    <input
                        type="checkbox"
                        id="cancelled"
                        [checked]="workflowCancelDenied"
                        (change)="onChange($event, 'cancelled')"
                    />
                    Change Event State to "Cancelled" when any tasks are denied
                </label>
            </div>
            -->

            <div class="c-displayBlock c-padding-bottom--double">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="save()"
                >
                    {{ this.loading ? "Saving...." : "Save" }}
                </button>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
            </div>
        </div>
    `,
    styles: [],

    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25WorkflowComponent implements OnInit {
    @Output() modelValueChange = new EventEmitter();
    init: boolean = false;
    loading: boolean = false;
    workflowConfirmComplete: boolean = false;
    workflowCancelDenied: boolean = false;
    workflowChained: boolean = false;
    initLoading: boolean = false;
    initWorkflowConfirmComplete: boolean = false;
    initWorkflowCancelDenied: boolean = false;
    initWorkflowChained: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this;
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(
                ["workflowChained", "workflowConfirmComplete", "workflowCancelDenied"],
                "S",
            ),
        }).then((resp) => {
            resp.prefs.workflowChained.value === "T" ? (this.workflowChained = true) : (this.workflowChained = false);
            resp.prefs.workflowConfirmComplete.value === "T"
                ? (this.workflowConfirmComplete = true)
                : (this.workflowConfirmComplete = false);
            resp.prefs.workflowCancelDenied.value === "T"
                ? (this.workflowCancelDenied = true)
                : (this.workflowCancelDenied = false);
            //   console.log({
            //       workflowChained: this.workflowChained,
            //       workflowConfirmComplete: this.workflowConfirmComplete,
            //       workflowCancelDenied: this.workflowCancelDenied,
            //   });
            this.initWorkflowChained = S25Util.deepCopy(this.workflowChained);
            this.initWorkflowConfirmComplete = S25Util.deepCopy(this.workflowConfirmComplete);
            this.initWorkflowCancelDenied = S25Util.deepCopy(this.workflowCancelDenied);
            this.initLoading = false;
            this.init = true;
            this.cd.detectChanges();
        });
    }

    onChange(event: any, name: string) {
        if (name === "complete") {
            this.workflowConfirmComplete = event.target.checked;
        } else {
            this.workflowCancelDenied = event.target.checked;
        }
    }

    onToggleChange(event: any) {
        this.workflowChained = event;
        TelemetryService.sendWithSub("SysSettings", "Wrkflw", "Tiered" + (this.workflowChained ? "On" : "Off"));
        this.cd.detectChanges();
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();
        let workflowChained: string = "";
        let workflowConfirmComplete: string = "";
        let workflowCancelDenied: string = "";
        //in DB value true = T, false = F;
        this.workflowChained ? (workflowChained = "T") : (workflowChained = "F");
        this.workflowConfirmComplete ? (workflowConfirmComplete = "T") : (workflowConfirmComplete = "F");
        this.workflowCancelDenied ? (workflowCancelDenied = "T") : (workflowCancelDenied = "F");

        let promiseArr = [];
        // this.initWorkflowChained !== this.workflowChained &&
        promiseArr.push(PreferenceService.setPreference("workflowChained", workflowChained, "S"));

        /*
        this.initWorkflowConfirmComplete !== this.workflowConfirmComplete &&
            promiseArr.push(PreferenceService.setPreference("workflowConfirmComplete", workflowConfirmComplete, "S"));

        this.initWorkflowCancelDenied !== this.workflowCancelDenied &&
            promiseArr.push(PreferenceService.setPreference("workflowCancelDenied", workflowCancelDenied, "S"));

         */

        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        }
    }

    cancel() {
        this.getPrefs();
        this.loading = false;
        this.cd.detectChanges();
    }
}
