//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";
import { SystemSettingsUtil } from "../../system-settings/system.settings.util";
import { Preference } from "../../../pojo/PeferenceI";

@TypeManagerDecorator("search-options")
@Component({
    selector: "search-options",
    template: `
        <div *ngFor="let g of this.getOptions; let i = index">
            <s25-ng-shift-selectable-checkbox
                *ngIf="g.show"
                [id]="g.section_id"
                [index]="i"
                [group]="'searchSettings-' + prefName"
                [modelValue]="g.visible === 'true'"
                (modelValueChange)="checked($event, i)"
                >{{ g.display_name }}</s25-ng-shift-selectable-checkbox
            >
        </div>

        <div class="c-displayBlock c-padding-bottom--double">
            <button class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter" (click)="save()">
                {{ this.loading ? "Saving...." : "Save" }}
            </button>
            <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
        </div>

        <div
            *ngIf="this.message && this.message !== ''"
            class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
            role="alert"
        >
            <div class="cn-alert__icon cn-icon" name="alert--info">
                <svg class="cn-svg-icon" role="img">
                    <title>Success Alert</title>
                    <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                    ></use>
                </svg>
            </div>
            <div class="cn-alert__label">
                <span>{{ this.message }}</span>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchOptionsComponent implements OnInit {
    @Input() prefName: string = "";
    @Input() prefType: Preference.PrefType = "S";
    @Input() objectName: string = "";
    getOptions: any;
    loading: boolean = false;
    message: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    checked(yes: boolean, index: number) {
        this.getOptions[index].visible = yes ? "true" : "false";
        this.cd.detectChanges();
    }

    getPrefs() {
        let option = SystemSettingsUtil.getOptionName(this.objectName);
        PreferenceService.getPreferences([this.prefName], this.prefType).then((resp) => {
            let optionObj = resp[this.prefName]?.value && resp[this.prefName].value[option];
            if (optionObj?.index) {
                this.getOptions = S25Util.array.forceArray(resp[this.prefName].value[option].index);
                this.getOptions = SystemSettingsUtil.decorateOptions(
                    this.getOptions,
                    SystemSettingsUtil.displayOptions(this.objectName),
                );
            } else {
                this.getOptions = [];
                this.getOptions = SystemSettingsUtil.decorateOptions(
                    this.getOptions,
                    SystemSettingsUtil.displayOptions(this.objectName),
                );
            }

            this.cd.detectChanges();
        });
    }

    save() {
        //set new preferce value in the following format,  example in database expec <index name="Index" section_id="keyword" visible="true"/>
        if (this.prefName === "25L_space_system_options") {
            this.getOptions = this.getOptions || [];
        }

        let newValue = SystemSettingsUtil.setOptions(this.objectName, this.getOptions);
        this.loading = true;
        this.cd.detectChanges();

        return S25Util.all({
            setPref: PreferenceService.setPreference(this.prefName, newValue, this.prefType),
        }).then((resp) => {
            this.loading = false;
            this.message = "Success!";
            this.cd.detectChanges();
        });
    }

    cancel() {
        this.getPrefs();
    }
}
